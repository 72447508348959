/* eslint-disable prefer-const */
// We have a pattern to use `let` in these files. Don't mess with it.

import { UserManager, type UserManagerSettings } from "oidc-client-ts";

let logisticsApiUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_LOGISTICS_BACKEND
    ? "https://localhost:7240"
    : "https://logistics-dev.medoma.com";
let mapboxApiUrl = "https://api.mapbox.com";
let patientApiUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_PATIENTS_BACKEND
    ? "https://localhost:7023"
    : "https://pas-dev.medoma.com";
let authApiUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_AUTH_BACKEND
    ? "https://localhost:5001"
    : "https://auth-dev.medoma.com";
let notificationApiUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_NOTIFICATION_BACKEND
    ? "https://localhost:7240/notification"
    : "https://logistics-dev.medoma.com/notification";
let pushyAppId = "675ad8c47a327a8229eef939";
let environment = import.meta.env.DEV ? "local" : "dev";

const hostname = window?.location?.hostname;

// Inspired by https://daveceddia.com/multiple-environments-with-react/
if (hostname === "go.medoma.com") {
  logisticsApiUrl = "https://logistics.medoma.com";
  patientApiUrl = "https://pas.medoma.com";
  authApiUrl = "https://auth.medoma.com";
  notificationApiUrl = "https://logistics.medoma.com/notification";
  pushyAppId = "675ada15377b179337a40445";
  environment = "prod";
} else if (hostname === "go.test.medoma.com") {
  logisticsApiUrl = "https://logistics-test.medoma.com";
  patientApiUrl = "https://pas-test.medoma.com";
  authApiUrl = "https://auth-test.medoma.com";
  notificationApiUrl = "https://logistics-test.medoma.com/notification";
  pushyAppId = "675aac7c377b179337a4043f";
  environment = "test";
} else if (hostname === "go.demo.medoma.com") {
  logisticsApiUrl = "https://logistics-demo.medoma.com";
  patientApiUrl = "https://pas-demo.medoma.com";
  authApiUrl = "https://auth-demo.medoma.com";
  notificationApiUrl = "https://logistics-demo.medoma.com/notification";
  pushyAppId = "675ad9e6377b179337a40443";
  environment = "demo";
}

const oidcConfig: UserManagerSettings = {
  authority: authApiUrl,
  client_id: "medoma-go",
  redirect_uri: `${window.location.origin}/ids-login`,
  scope:
    "patient.api logistic.api user_type mfa employee_profile issued_for_app openid organization",
};
export const userManager = new UserManager(oidcConfig);

export const {
  LOGISTICS_API_URL,
  MAPBOX_API_URL,
  PATIENT_API_URL,
  AUTH_API_URL,
  NOTIFICATION_API_URL,
  PUSHY_APP_ID,
  ENVIRONMENT,
  CHAT_ENVIRONMENT,
} = {
  LOGISTICS_API_URL: logisticsApiUrl,
  MAPBOX_API_URL: mapboxApiUrl,
  PATIENT_API_URL: patientApiUrl,
  AUTH_API_URL: authApiUrl,
  NOTIFICATION_API_URL: notificationApiUrl,
  PUSHY_APP_ID: pushyAppId,
  ENVIRONMENT: environment,
  CHAT_ENVIRONMENT: environment === "local" ? "dev" : environment,
};
