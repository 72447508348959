import clsx from "clsx";
import styles from "./StatusTag.module.scss";
import CheckFilledIcon from "@components/icons/CheckFilledIcon";
import ArrowRightFilledIcon from "@components/icons/ArrowRightFilledIcon";
import type { IStatus } from "./statusTagUtils";
import { statusDictionary } from "./statusTagUtils";
import { useLingui } from "@lingui/react";
import { i18n } from "@lingui/core";

type IStatusTag = {
  status: IStatus;
  variant?:
    | "icon"
    | "icon-with-timestamp"
    | "icon-and-text"
    | "icon-and-text-with-timestamp";
  size?: "tiny" | "small" | "medium";
  timestamp?: Date;
};

export const StatusTag = ({
  status,
  variant = "icon",
  size = "small",
  timestamp,
}: IStatusTag) => {
  const { _ } = useLingui();
  const label = _(statusDictionary[status]);

  const Icon = () => (
    <div className={styles.iconWrapper}>
      <div className={clsx(styles.icon, styles[`icon-${status}`])}>
        {status === "travellingTo" ? <ArrowRightFilledIcon /> : null}
        {status === "finished" ? <CheckFilledIcon /> : null}
      </div>
    </div>
  );

  if (variant === "icon") {
    return (
      <div
        className={clsx(
          styles.statusTag,
          styles[`variant-${variant}`],
          styles[`color-${status}`],
          styles[`size-${size}`],
        )}
      >
        <Icon />
      </div>
    );
  }

  if (variant === "icon-with-timestamp") {
    return (
      <div
        className={clsx(
          styles.statusTag,
          styles[`variant-${variant}`],
          styles[`color-${status}`],
          styles[`size-${size}`],
        )}
      >
        <Icon />
        {timestamp ? (
          <div className={styles.text}>
            {i18n.date(timestamp, { timeStyle: "short" })}
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div
      className={clsx(
        styles.statusTag,
        styles[`variant-${variant}`],
        styles[`color-${status}`],
        styles[`size-${size}`],
      )}
    >
      <Icon />
      <div className={styles.text}>
        <span>{label}</span>
        {timestamp ? (
          <span>{i18n.date(timestamp, { timeStyle: "short" })}</span>
        ) : null}
      </div>
    </div>
  );
};
