import { useAuth } from "react-oidc-context";
import { z } from "zod";

export const useUnit = () => {
  const { user } = useAuth();
  if (!user) {
    throw new Error(
      "No user found. This is usually because the hook is executed outside of an AuthProvider, or because the authentication is not completed yet.",
    );
  }

  const unit = z.string().parse(user.profile.unit, {
    errorMap: () => ({
      message:
        "The id_token did not include a 'unit' property. Did you forget the 'organization' scope?",
    }),
  });

  return unit;
};
