import { categorySchema, type IActivityCategory } from "@models/activities";
import styles from "./ActivityTag.module.scss";
import clsx from "clsx";
import HouseIcon from "@components/icons/HouseIcon";
import VideoCameraIcon from "@components/icons/VideoCameraIcon";
import AvatarIcon from "@components/icons/AvatarIcon";
import MedicalCircleIcon from "@components/icons/MedicalCircleIcon";
import HealthParametersIcon from "@components/icons/HealthParametersIcon";
import { Link } from "@components/Link/Link";
import { Heading } from "@components/Heading/Heading";

type IActivityGroupTag = {
  category: IActivityCategory;
  title: string;
  patientId?: string;
};

export const ActivityTag = ({
  category,
  title,
  patientId,
}: IActivityGroupTag) => {
  const renderIcon = () => {
    switch (category) {
      case categorySchema.Values.HomeVisit:
        return <HouseIcon />;
      case categorySchema.Values.VideoCall:
        return <VideoCameraIcon />;
      case categorySchema.Values.PatientTask:
        return <AvatarIcon />;
      case categorySchema.Values.AdminTask:
        return <MedicalCircleIcon />;
      case categorySchema.Values.PatientMeasurementTask:
        return <HealthParametersIcon />;
      default:
        return null;
    }
  };

  const shouldLinkToPatient = Boolean(patientId);

  return (
    <div className={clsx([styles.container, styles[`category-${category}`]])}>
      <div className={styles.icon}>{renderIcon()}</div>
      {shouldLinkToPatient ? (
        <Heading level="h2" size="h3" className={styles.title}>
          <Link to={`/patients/${patientId}`}>{title}</Link>
        </Heading>
      ) : (
        <Heading level="h2" size="h3" className={styles.title}>
          {title}
        </Heading>
      )}
    </div>
  );
};
