import type { IMedicalCompetence } from "@models/shifts";

export const competenceColor: Record<
  IMedicalCompetence,
  {
    colorName: "green" | "blue" | "red" | "orange" | "purple";
    primaryColor: string;
    backgroundColor: string;
  }
> = {
  Dietitian: {
    colorName: "orange",
    primaryColor: "var(--color-orange)",
    backgroundColor: "var(--color-orange-container)",
  },
  DistrictNurse: {
    colorName: "blue",
    primaryColor: "var(--color-blue-40)",
    backgroundColor: "var(--color-blue-container)",
  },
  MedicalDoctor: {
    colorName: "red",
    primaryColor: "var(--color-red-40)",
    backgroundColor: "var(--color-red-container)",
  },
  MedicalSocialWorker: {
    colorName: "orange",
    primaryColor: "var(--color-orange)",
    backgroundColor: "var(--color-orange-container)",
  },
  NurseAssistant: {
    colorName: "green",
    primaryColor: "var(--color-green-40)",
    backgroundColor: "var(--color-green-container)",
  },
  NursePractitioner: {
    colorName: "blue",
    primaryColor: "var(--color-blue-40)",
    backgroundColor: "var(--color-blue-container)",
  },
  OccupationalTherapist: {
    colorName: "orange",
    primaryColor: "var(--color-orange)",
    backgroundColor: "var(--color-orange-container)",
  },
  Physiotherapist: {
    colorName: "orange",
    primaryColor: "var(--color-orange)",
    backgroundColor: "var(--color-orange-container)",
  },
  Psychologist: {
    colorName: "orange",
    primaryColor: "var(--color-orange)",
    backgroundColor: "var(--color-orange-container)",
  },
  Support: {
    colorName: "purple",
    primaryColor: "var(--color-purple-40)",
    backgroundColor: "var(--color-purple-container)",
  },
};
