import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { fetchPatients, resolvePatient } from "./Patients";
import { logisticsApi } from "./ApiClient";
import { medicalCompetenceSchema } from "@models/shifts";
import { generateQueryString } from "./Helpers";
import { deduplicatePrimitiveArray } from "@/Utils/arrayUtils";
import { dateSchema, format } from "@models/date-and-time";
import type { IActivityOccurrenceStatus } from "@models/activities";
import {
  activityOccurrenceOrGroupSchema,
  categorySchema,
  activityOccurrencesAndGroupsWithPatientIdsSchema,
  adminTaskOccurrenceSchema,
  videoActivityOccurrenceSchema,
  isGroup,
} from "@models/activities";
import { startOfToday } from "date-fns";

const parseAndEnrichActivityOccurrencesAndGroups = async (
  probableActivityOccurrencesAndGroups: unknown,
) => {
  const parsedActivityOccurrencesAndGroups =
    activityOccurrencesAndGroupsWithPatientIdsSchema.parse(
      probableActivityOccurrencesAndGroups,
    );

  if (parsedActivityOccurrencesAndGroups.length === 0) {
    return parsedActivityOccurrencesAndGroups as [];
  }

  const patientIds = deduplicatePrimitiveArray(
    // AdminTask activities sometimes miss patientId
    parsedActivityOccurrencesAndGroups
      .map(({ patientId }) => patientId)
      .filter(Boolean) as string[],
  );
  const patients = await fetchPatients({ patientIds });

  const enrichedActivityOccurrencesAndGroups =
    parsedActivityOccurrencesAndGroups.map((activityOccurrenceOrGroup) => {
      const patient = resolvePatient({
        patientId: activityOccurrenceOrGroup.patientId,
        patients,
      });

      if (isGroup(activityOccurrenceOrGroup)) {
        const group = activityOccurrenceOrGroup;
        return {
          ...group,
          patient,
          occurrences: group.occurrences.map((occurrence) => {
            return {
              ...occurrence,
              patient,
            };
          }),
        };
      } else {
        const activityOccurrence = activityOccurrenceOrGroup;
        return {
          ...activityOccurrence,
          patient,
        };
      }
    });

  return z
    .array(activityOccurrenceOrGroupSchema)
    .parse(enrichedActivityOccurrencesAndGroups);
};

const activityKeys = {
  all: ["activities"] as const,
  lists: () => [...activityKeys.all, "list"] as const,
  list: (filters: Record<string, unknown>) =>
    [...activityKeys.lists(), filters] as const,
  detail: (id: string) => [...activityKeys.all, id, "details"] as const,
};

export const activityOccurrenceAndGroupKeys = {
  all: () => [...activityKeys.all, "occurrences"] as const,
  lists: () => [...activityOccurrenceAndGroupKeys.all(), "list"] as const,
  list: (filters: Record<string, unknown>) =>
    [...activityOccurrenceAndGroupKeys.lists(), filters] as const,
  detail: (id: string) =>
    [...activityOccurrenceAndGroupKeys.all(), id, "details"] as const,
};

export const finishActivityOccurrence = async (
  activityId: string,
  occurrenceId: string,
) => {
  await logisticsApi.post(
    `activities/${activityId}/occurrences/${occurrenceId}/finish`,
  );
};

export const resetActivityOccurrence = async (
  activityId: string,
  occurrenceId: string,
) => {
  await logisticsApi.post(
    `activities/${activityId}/occurrences/${occurrenceId}/reset`,
  );
};
const ANY_TIME_OF_DAY = "AnyTimeOfDay" as const;
const anyTimeOfDaySchema = z.literal(ANY_TIME_OF_DAY);

const timesSchema = z.union([z.array(z.string()), anyTimeOfDaySchema]);

const weekdaySchema = z.enum([
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
]);

const intervalSchema = z.number().int().positive();

const frequencyBaseSchema = z.object({
  start: dateSchema,
  end: dateSchema.nullish(),
  span: z.string(),
  times: timesSchema,
});

const weekdayFrequencySchema = frequencyBaseSchema.extend({
  days: z.array(weekdaySchema),
});

const intervalFrequencySchema = frequencyBaseSchema.extend({
  interval: intervalSchema,
});

const frequencySchema = z.union([
  weekdayFrequencySchema,
  intervalFrequencySchema,
]);

const oneTimeSchema = z.object({
  date: z.string(),
  time: z.union([z.string(), anyTimeOfDaySchema]),
  span: z.string(),
});

const activityScheduleSchema = z.union([oneTimeSchema, frequencySchema]);

const activityStatusSchema = z.enum(["active", "closed"]);

const activityBaseSchema = z.object({
  id: z.string().uuid(),
  title: z.string(),
  description: z.string().nullish(),
  schedule: activityScheduleSchema,
  recurring: z.boolean(),
  category: categorySchema,
  duration: z.number().int(),
  status: activityStatusSchema,
  hidden: z.boolean(),
});

const newActivityOmit = {
  id: true,
  recurring: true,
  schedule: true,
  status: true,
} as const;

const newActivityExtend = {
  activityId: z.string().uuid(),
  timespan: z.string(),
  startDate: z.string(),
  templateId: z.string().uuid().optional(),
  templateRevision: z.number().int().optional(),
};

// HOME VISIT
const activityHomeVisitSchema = activityBaseSchema.extend({
  category: z.literal(categorySchema.Values.HomeVisit),
  doubleStaffing: z.boolean(),
  requiredCompetences: z.array(medicalCompetenceSchema),
  patientId: z.string().uuid(),
});

const newHomeVisitActivityBaseSchema = activityHomeVisitSchema
  .omit(newActivityOmit)
  .extend(newActivityExtend);

const _newJitActivityInVisitSchema = newHomeVisitActivityBaseSchema
  .pick({
    activityId: true,
    title: true,
    description: true,
  })
  .extend({
    visitId: z.string(),
    routeId: z.string().uuid(),
  });

export type INewJitActivityInVisit = z.infer<
  typeof _newJitActivityInVisitSchema
>;

export const addJitActivityInVisit = async (
  newActivityInVisit: INewJitActivityInVisit,
) => {
  await logisticsApi.post("/activities/contextual", newActivityInVisit);
};

// TODO: Remove when switching to activity grouping
const _newJustInTimeActivitySchema = newHomeVisitActivityBaseSchema
  .pick({
    activityId: true,
    title: true,
    description: true,
    patientId: true,
  })
  .extend({
    visitId: z.string(),
    routeId: z.string().uuid(),
  });

// TODO: Remove when switching to activity grouping
export type INewJustInTimeActivity = z.infer<
  typeof _newJustInTimeActivitySchema
>;

// TODO: Remove when switching to activity grouping
export async function addJustInTimeActivity(
  newJustInTimeActivity: INewJustInTimeActivity,
) {
  await logisticsApi.post("/activities/jit", newJustInTimeActivity);
}

const fetchOtherEmployeeActivityOccurrences = async (
  from: string,
  to: string,
  status?: IActivityOccurrenceStatus[],
) => {
  const queryString = generateQueryString({
    from,
    to,
    category: [
      categorySchema.Values.AdminTask,
      categorySchema.Values.VideoCall,
    ],
    status,
  });
  const activityOccurrencesAndGroupsResponse = await logisticsApi.get(
    `/ambulating/occurrences${queryString}`,
  );
  const activityOccurrencesAndGroups =
    await parseAndEnrichActivityOccurrencesAndGroups(
      activityOccurrencesAndGroupsResponse.data,
    );

  const otherEmployeeActivityOccurrencesSchema = z.array(
    z.union([adminTaskOccurrenceSchema, videoActivityOccurrenceSchema]),
  );

  return otherEmployeeActivityOccurrencesSchema.parse(
    activityOccurrencesAndGroups,
  );
};

export const useOtherEmployeeActivityOccurrences = () => {
  const today = format(startOfToday(), "yyyy-MM-dd");
  return useQuery({
    queryKey: activityOccurrenceAndGroupKeys.list({
      assignee: "me",
      from: today,
      to: today,
      category: [
        categorySchema.Values.AdminTask,
        categorySchema.Values.VideoCall,
      ],
    }),
    queryFn: () => fetchOtherEmployeeActivityOccurrences(today, today),
  });
};
