import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import styles from "./Header.module.scss";
import Person from "@/components/Person/Person";
import { LoadingOverlay } from "@components/Loading/Loading";
import DropdownMenu from "@/components/DropdownMenu/DropdownMenu";
import LogoutIcon from "@components/icons/SVG/logout.svg?react";
import { Link, useLocation, useNavigate } from "react-router";
import LogoText from "@components/icons/LogoText";
import { useCurrentEmployee } from "@/api/Employee";
import SentryFeedbackButton from "@/components/SentryFeedbackButton/SentryFeedbackButton";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { useUnreadNotificationCounts } from "@chat/useUnreadNotificationCounts";
import { UnstyledLink } from "@components/Link/Link";
import NotificationCircle from "@components/NotificationCircle/NotificationCircle";
import ChatConversationIcon from "@components/icons/ChatConversation";
import { IconButton } from "@components/Button/Button";
import CaretLeftIcon from "@components/icons/CaretLeftIcon";
import { employeeName } from "@models/shifts";
import { useAuth } from "react-oidc-context";
import { getEmployeeRoomName } from "@chat/roomAlias";
import { useUnit } from "@/api/Users";
import { CHAT_ENVIRONMENT } from "@/Utils/EnvUtils";

const HomeLink = () => (
  <Link aria-label={t`Startsida`} to="/routes" className={styles.homeLink}>
    <LogoText />
  </Link>
);

const NavigateBack = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.navigateBackButtonWrapper}>
      <IconButton aria-label={t`Gå tillbaka`} onClick={() => navigate(-1)}>
        <CaretLeftIcon />
      </IconButton>
    </div>
  );
};

const ChatLink = () => {
  const { data: employee } = useCurrentEmployee();
  useEffect(() => {
    if (employee) {
      Sentry.setUser({
        username: employeeName(employee),
      });
    }
  }, [employee]);

  const unit = useUnit();
  const employeeId = employee?.id.toString() ?? ""; // TODO: replace with employee_id from user profile once ready
  const numberOfUnreadMessages = useUnreadNotificationCounts({
    roomName: getEmployeeRoomName({ employeeId, env: CHAT_ENVIRONMENT, unit }),
  });

  const hasCompletedInitialSync = numberOfUnreadMessages !== undefined;

  const hasUnreadChatMessages =
    hasCompletedInitialSync && numberOfUnreadMessages > 0;

  return (
    <UnstyledLink to="chat">
      <div className={styles.iconLink}>
        <LoadingOverlay show={!hasCompletedInitialSync}>
          <NotificationCircle
            placement="top-left"
            isVisible={hasUnreadChatMessages}
            number={numberOfUnreadMessages}
          >
            <ChatConversationIcon />
          </NotificationCircle>
        </LoadingOverlay>
      </div>
    </UnstyledLink>
  );
};

const Header = () => {
  const { user, signoutRedirect } = useAuth();
  const { data: employee } = useCurrentEmployee();
  const { pathname } = useLocation();
  const isOnAmbulatingHome = pathname === "/routes";

  const CustomTrigger = () => (
    <Person
      name={user?.profile.given_name ?? ""}
      photoUrl={employee?.imageURL ?? null}
    />
  );

  return (
    <header className={styles.header}>
      {isOnAmbulatingHome ? <div /> : <NavigateBack />}
      <div className={styles.alignCenter}>
        <HomeLink />
      </div>
      <div className={styles.alignRight}>
        <ChatLink />
        <DropdownMenu
          trigger={{
            custom: <CustomTrigger />,
            ariaLabel: t`Meny`,
          }}
        >
          <DropdownMenu.Item content={<SentryFeedbackButton />} />
          <DropdownMenu.Separator />
          <DropdownMenu.Item
            action={() => signoutRedirect()}
            content={
              <>
                <LogoutIcon /> <Trans>Logga ut</Trans>
              </>
            }
          />
        </DropdownMenu>
      </div>
    </header>
  );
};

export default Header;
