import { useEffect } from "react";
import type { EventSourceMessage } from "@microsoft/fetch-event-source";
import {
  fetchEventSource,
  EventStreamContentType,
} from "@microsoft/fetch-event-source";
import { LOGISTICS_API_URL, PATIENT_API_URL } from "./EnvUtils";
import { useAuth } from "react-oidc-context";

class RetriableError extends Error {}
class FatalError extends Error {}
class AuthenticationError extends Error {}
class AbortError extends Error {}

export function useEventSource(
  source: "logistics" | "patient",
  eventHandlers: ReadonlyArray<{
    relevantEvents: ReadonlyArray<string>;
    handler: (_message: EventSourceMessage) => void;
  }>,
) {
  const { isAuthenticated, user } = useAuth();
  const sseUrl = `${
    source === "patient" ? PATIENT_API_URL : LOGISTICS_API_URL
  }/center/notifications`;

  useEffect(() => {
    const ctrl = new AbortController();
    const setUpConnection = async () => {
      const token = user?.access_token;
      const bearerToken = `Bearer ${token}`;
      await fetchEventSource(sseUrl, {
        openWhenHidden: true,
        headers: {
          Authorization: bearerToken,
        },
        // Make request abortable
        // Honestly doesn't seem to work, so we have below workaround too*:
        signal: ctrl.signal,
        // onopen executes on the first response from the backend, typically a keep-alive within 0-30s.
        async onopen(response) {
          // *When not aborted
          // throw AbortError manually to force abort.
          if (ctrl.signal.aborted) {
            throw new AbortError();
          }
          if (
            response.ok &&
            response.headers.get("content-type") === EventStreamContentType
          ) {
            return; // everything's good
          } else if (response.status === 401) {
            // authentication-errors are usually resolved by getting a new token.
            throw new AuthenticationError();
          } else if (
            response.status >= 400 &&
            response.status < 500 &&
            response.status !== 429
          ) {
            // client-side errors are usually non-retriable:
            throw new FatalError();
          } else {
            throw new RetriableError();
          }
        },
        onmessage(event) {
          const eventName = event.event;
          if (import.meta.env.DEV && import.meta.env.VITE_DEBUG_SSE) {
            console.debug("[EventSource]", event);
          }
          eventHandlers.forEach(({ relevantEvents, handler }) => {
            if (relevantEvents.includes(eventName)) {
              handler(event);
            }
          });
        },
        onclose() {
          // if the server closes the connection unexpectedly, retry:
          throw new RetriableError();
        },
        onerror(err) {
          // Any error thrown within `fetchEventSource` will first be caught here.
          if (err instanceof AbortError) {
            throw err; // avoid automatic retry
          }
          if (err instanceof FatalError) {
            throw err; // rethrow to stop the operation
          }
          if (err instanceof AuthenticationError) {
            throw err; // rethrow to stop the operation
          }
          // do nothing to automatically retry. You can also
          // return a specific retry interval here.
        },
      });
    };

    const FIVE_MINUTES = 300_000;

    // We expect AuthenticationErrors every ~60-90 minutes due to built-in retry mechanisms in fetchEventSource.
    // This function retries AuthenticationErrors as long as they don't occur more than every 5 minutes.
    const retryableSetUpConnection = () => {
      let lastFailureTime: number | null = null;

      return new Promise((resolve, reject) => {
        function retry() {
          setUpConnection()
            .then(resolve)
            .catch((error) => {
              const timeSinceLastFailure = lastFailureTime
                ? Date.now() - lastFailureTime
                : Infinity;
              if (
                error instanceof AuthenticationError &&
                timeSinceLastFailure >= FIVE_MINUTES
              ) {
                lastFailureTime = Date.now();
                retry();
              } else if (error instanceof AbortError) {
                // most likely a duplicate request, aborted in the effect clean-up. Do nothing.
              } else {
                reject(error);
              }
            });
        }

        retry();
      });
    };

    if (isAuthenticated) {
      retryableSetUpConnection();
    }

    return () => {
      ctrl.abort();
      // When this effect is cleaned up, abort any outstanding connections.
      // This will take 0-30 seconds depending on when `onopen` gets the first response.
    };
  }, [eventHandlers, isAuthenticated, sseUrl, user?.access_token]);
}
