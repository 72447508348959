// https://en.wikipedia.org/wiki/List_of_ISO_639_language_codes
// https://en.wikipedia.org/wiki/ISO_639-1
const SUPPORTED_ISO_639_1_CODES = ["en", "sv", "de"] as const;
const DEFAULT_ISO_639_1_CODE = "en" as const;

export const retrieveBrowserLocale = () => {
  // This corresponds to the user's top selected browser language.
  const browserLocale = navigator.language;

  return retrieveLocale(browserLocale);
};

const retrieveLocale = (locale: string) => {
  const matchingSupportedTopLevelLanguageCode = SUPPORTED_ISO_639_1_CODES.find(
    (supportedTopLevelLanguageCode) =>
      // Users often have `en-US` or similar selected.
      locale.startsWith(supportedTopLevelLanguageCode),
  );
  return matchingSupportedTopLevelLanguageCode ?? DEFAULT_ISO_639_1_CODE;
};
